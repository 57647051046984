import React from "react";
import { motion } from "framer-motion";
import {
  FileText,
  Book,
  CreditCard,
  MessageSquare,
  BarChart,
  Users,
  ArrowLeft,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import AfterLoginComp from "./DashLogo";
export default function Component() {
  const features = [
    {
      icon: <FileText className="w-6 h-6" />,
      title: "AI-Generated Notes",
      description: "Comprehensive notes created from your uploaded materials",
    },
    {
      icon: <Book className="w-6 h-6" />,
      title: "Smart Summaries",
      description: "Concise summaries of key concepts and topics",
    },
    {
      icon: <CreditCard className="w-6 h-6" />,
      title: "Interactive Flashcards",
      description: "Engaging flashcards for effective revision",
    },
    {
      icon: <MessageSquare className="w-6 h-6" />,
      title: "Custom Chatbot",
      description: "AI-powered assistant to answer student queries",
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Progress Tracking",
      description: "Monitor student engagement and performance",
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Collaborative Learning",
      description: "Foster peer-to-peer learning and discussions",
    },
  ];
  const navigate = useNavigate();
  const navigateFn = () => {
    navigate("/deploy-student-detail");
  };
  return (
    <div className="min-h-screen flex flex-col bg-blue-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <Link
            to="/dashboard"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </Link>
          <div className="flex items-center">
            <AfterLoginComp />
          </div>
        </div>
      </header>

      <main className="w-full flex-grow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <div className="bg-gradient-to-br from-blue-600 to-blue-800 text-white p-8 rounded-xl shadow-2xl">
            <h2 className="text-3xl font-bold mb-4 text-center">
              Deploy to Students
            </h2>
            <p className="text-lg mb-8 text-center font-light">
              Transform your course materials into AI-powered learning
              resources. Enhance student engagement and track progress.
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-6 flex flex-col items-center text-center transition-all duration-300 hover:bg-opacity-20"
                >
                  <div className="bg-blue-500 p-3 rounded-full mb-4">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">
                    {feature.title}
                  </h3>
                  <p className="text-blue-100">{feature.description}</p>
                </motion.div>
              ))}
            </motion.div>
            <div className="mt-12 text-center">
              <button
                onClick={navigateFn}
                className="bg-white text-blue-600 px-8 py-3 rounded-full font-bold text-lg transition-all duration-300 hover:bg-blue-100 hover:shadow-lg"
              >
                Start Deploying
              </button>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link
              href="/privacy"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}
