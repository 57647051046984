import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/");
  };
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-800">404</h1>
        <p className="mt-4 text-lg text-gray-600">Page Not Found</p>
        <button
          onClick={handleRedirect}
          className="mt-6 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-blue-600 transition-colors duration-300"
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
};

export default NotFound;
