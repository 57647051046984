import React, { useEffect, useState } from "react";
import TeacherDashboard from "./TeacherDashboard";
import StudentDashboard from "./student/StudentComp";

export default function Dashboard() {
  const [type, setType] = useState("");
  useEffect(() => {
    setType(localStorage.getItem("user-type"));
  }, []);

  return (
    <div className="">
      {type == "student" ? <StudentDashboard /> : <TeacherDashboard />}
    </div>
  );
}
