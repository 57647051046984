import React, { useState, useEffect } from "react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import Markdown from "react-markdown";
import axios from "axios";

const Chatbot = ({ file_id }) => {
  const [typing, setTyping] = useState(false);
  const [chatInput, setChatInput] = useState("");
  const [chatResponse, setChatResponse] = useState([]);
  const [chatQuestion, setChatQuestion] = useState("");

  const updateInputFn = (e) => {
    let changeText = writeInput(e);
    setChatInput(changeText);
  };


  const getChatHistory =async()=>{
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/assignment-chat-history`,
        {
          file_id: file_id,
          class_code: localStorage.getItem("class_code"),
          
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          }
        }
      );

      setTyping(false);
     console.log("respinse", response.data.data);
     
      if (response.data.status) {
        setChatResponse(response.data.data);
        setChatQuestion("");
      }
    } catch (error) {
      setTyping(false);
      console.log("error", error.message);
    }
  }

useEffect(()=>{
  getChatHistory()
},[])

  const writeInput = (str) => {
    return str.replace(/(<([^>]+)>)/gi, "");
  };

  const sendMessage = async (value) => {
    if (!value.trim()) return; // Prevent sending empty messages
    setTyping(true);
    setChatQuestion(value);
    setChatInput(""); // Clear input field after sending the message
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/assignment-chat`,
        {
          file_id: file_id,
          class_code: localStorage.getItem("class_code"),
          question: value,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          }
        }
      );

      setTyping(false);

      if (response.data.status) {
        setChatResponse((preData) => [...preData, response.data]);
        setChatQuestion("");
      }
    } catch (error) {
      setTyping(false);
      console.log("error", error.message);
    }
  };



  return (
    <div className="">
      <div style={{ position: "relative", height: "500px" }} className="mt-3">
        <MainContainer className="pt-3 ">
          <ChatContainer>
            <MessageList
              typingIndicator={
                typing ? <TypingIndicator content="Tutor is typing..." /> : null
              }
            >
              {chatResponse && chatResponse?.map((message, i) => (
                <React.Fragment key={i}>
                  <div className="chat_question-div">
                    <div className="chat_question">
                      <Markdown>
                        {message?.question?.replace(/&nbsp;/g, "")}
                      </Markdown>
                    </div>
                    <div className="pt-1 chat-time">{message.sentTime}</div>
                  </div>
                  <div className="chat_response-div">
                    <div className="chat_response">
                      <Markdown>
                        {message?.answer?.replace(/&nbsp;/g, "")}
                      </Markdown>
                    </div>
                    <div className="pt-1 chat-time">{message?.sentTime}</div>
                  </div>
                </React.Fragment>
              ))}
              {chatQuestion && (
                <div className="chat_question-div">
                  <div className="chat_question">
                    {chatQuestion.replace(/&nbsp;/g, "")}
                  </div>
                </div>
              )}
            </MessageList>

            <MessageInput
              placeholder={typing ? "Please wait for a response..." : "Type message here"}
              attachButton={false}
              onChange={updateInputFn}
              value={chatInput}
              onSend={() => sendMessage(chatInput)}
              disabled={typing} 
            />
          </ChatContainer>
        </MainContainer>
      </div>
    </div>
  );
};

export default Chatbot;
