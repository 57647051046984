import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LoaderCircle } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CheckCircle,
  FileText,
  BookOpen,
  Send,
  User,
  LogOut,
  Clock,
} from "lucide-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { signOut } from "firebase/auth";
import { auth } from "src/firebase";
import AfterLoginLogo from "./AfterLoginLogo";
import userImage from "../assets/default_user.png";
import Walkthrough from "./Walkthrough";
const features = [
  {
    icon: <CheckCircle className="w-8 h-8" />,
    title: "Assignment Grader",
    description: "Grade assignments with AI assistance",
    color: "blue",
    link: "/assignment-grader",
  },
  {
    icon: <FileText className="w-8 h-8" />,
    title: "Worksheet Generator",
    description: "Create custom worksheets for your class",
    color: "green",
    link: "/worksheet-generator",
  },
  {
    icon: <BookOpen className="w-8 h-8" />,
    title: "Interactive Lesson Planner",
    description: "Plan engaging lessons with AI assistance",
    color: "purple",
    link: "/lesson-planner",
  },
  {
    icon: <Send className="w-8 h-8" />,
    title: "Deploy to Students",
    description: "Share resources with your students",
    color: "pink",
    link: "/deploy-student",
  },
];

const iconVariants = {
  hover: (color) => ({
    scale: 1.2,
    rotate: 360,
    transition: { duration: 0.3 },
    color:
      color === "blue"
        ? "#3B82F6"
        : color === "green"
        ? "#10B981"
        : color === "purple"
        ? "#8B5CF6"
        : "#EC4899",
  }),
};

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [showNotifications, setShowNotifications] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [loader, setLoader] = useState(false);
  const getDashboardData = async () => {
    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/dashboard`,
        {
          class_code: "",
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("resoponse", response.data.data);
      if (response.data.status) {
        setResponseData(response.data.data);
        setLoader(false);
      }
    } catch (err) {
      console.log("err", err.message);
      toast.error(err.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);
  const userData = {
    name: responseData.username ? responseData.username : "",
    role: responseData.profession ? responseData.profession : "",
    avatar: userImage,
    quickStats: {
      studentsHelped: responseData.students_helped
        ? responseData.students_helped
        : 0,
      lessonsCreated: responseData.lessons_created
        ? responseData.lessons_created
        : 0,
      averageScore: responseData.average_score ? responseData.average_score : 0,
    },
  };

  const logOutApiCalling = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/logout`, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-type");

      window.location.href = "/";
    } catch (error) {
      console.log("error", error.message);
    }
  };
  const logOutFn = async () => {
    await signOut(auth)
      .then((res) => {
        console.log("res", res);
      })
      .catch((error) => {
        // An error happened.
      });
    logOutApiCalling();
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {!localStorage.getItem("walkthrough") && <Walkthrough />}
      <header className="bg-blue-600 text-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <AfterLoginLogo />
          </div>
          <nav className="flex items-center space-x-4">
            {/* <button
              onClick={() => setShowNotifications(!showNotifications)}
              className="relative hover:text-blue-200 transition-colors duration-200"
            >
              <Bell className="w-6 h-6" />
              <span className="sr-only">Notifications</span>
              <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white"></span>
            </button> */}
            <Link
              to="/profile"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              <User className="w-6 h-6" />
              <span className="sr-only">Profile</span>
            </Link>
            {/* <Link
              href="/settings"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              <Settings className="w-6 h-6" />
              <span className="sr-only">Settings</span>
            </Link> */}
            <button
              onClick={logOutFn}
              className="hover:text-blue-200 transition-colors duration-200"
            >
              <LogOut className="w-6 h-6" />
              <span className="sr-only">Logout</span>
            </button>
          </nav>
        </div>
      </header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8 bg-white rounded-lg shadow-md p-6 flex items-center">
          <img
            src={userData.avatar}
            alt={userData.name}
            className="w-20 h-20 rounded-full mr-6"
          />
          <div>
            <h1 className="text-3xl font-bold text-gray-900">
              Welcome back,
              <span className="capitalize">
                {userData.name ? userData.name + "!" : ""}
              </span>
            </h1>
            <p className="text-gray-600 capitalize">
              {userData.role ? userData.role : ""}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-blue-500 text-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold mb-2">Students Helped</h3>
            <p className="text-3xl font-bold">
              {loader ? (
                <div className="spinner-container">
                  <LoaderCircle className="spinner" size={30} />
                </div>
              ) : userData.quickStats.studentsHelped ? (
                userData.quickStats.studentsHelped
              ) : (
                0
              )}
            </p>
          </div>
          <div className="bg-green-500 text-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold mb-2">Lessons Created</h3>
            <p className="text-3xl font-bold">
              {loader ? (
                <div className="spinner-container">
                  <LoaderCircle className="spinner" size={30} />
                </div>
              ) : userData.quickStats.lessonsCreated ? (
                userData.quickStats.lessonsCreated
              ) : (
                0
              )}
            </p>
          </div>
          <div className="bg-purple-500 text-white rounded-lg shadow-md p-6">
            <h3 className="text-lg font-semibold mb-2">Average Score</h3>
            <p className="text-3xl font-bold">
              {loader ? (
                <div className="spinner-container">
                  <LoaderCircle className="spinner" size={30} />
                </div>
              ) : userData.quickStats.averageScore ? (
                userData.quickStats.averageScore
              ) : (
                0
              )}
            </p>
          </div>
        </div>

        <nav className="flex space-x-4 mb-8">
          {["dashboard", "courses", "analytics"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 rounded-md ${
                activeTab === tab
                  ? "bg-blue-600 text-white"
                  : "bg-white text-blue-600 hover:bg-blue-100"
              } transition-colors duration-200`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </nav>

        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {activeTab === "dashboard" && (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 ">
                {features.map((feature, index) => (
                  <Link to={feature.link} key={index}>
                    <motion.div
                      className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center text-center custom_height"
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.2 }}
                    >
                      <motion.div
                        variants={iconVariants}
                        whileHover="hover"
                        custom={feature.color}
                      >
                        {feature.icon}
                      </motion.div>
                      <h2 className="text-xl font-semibold mt-4 mb-2">
                        {feature.title}
                      </h2>
                      <p className="text-gray-600">{feature.description}</p>
                    </motion.div>
                  </Link>
                ))}
              </div>
            )}

            {activeTab === "courses" && (
              <div className="bg-white rounded-lg shadow-md p-6 ">
                <h2 className="text-2xl font-bold mb-4">Your Courses</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-center">
                  {responseData?.courses?.length > 0 &&
                    responseData.courses?.map((course, index) => (
                      <div key={index} className="bg-gray-100 rounded-md p-4">
                        <h3 className="text-lg font-semibold">
                          {course?.class_name}
                        </h3>
                        <p className="text-sm text-gray-600">
                          {course?.class_code}
                        </p>
                      </div>
                    ))}
                </div>
                {!responseData?.courses?.length && (
                  <div className="text-center text-xl font-medium">
                    You haven't uploaded a course yet
                  </div>
                )}
              </div>
            )}

            {activeTab === "analytics" && (
              <div className="bg-white rounded-lg shadow-md p-6 ">
                <h2 className="text-2xl font-bold mb-4">Analytics Overview</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div className="bg-blue-100 rounded-md p-4">
                    <h3 className="text-lg font-semibold">Students Helped</h3>
                    <p className="text-3xl font-bold">
                      {responseData.students_helped
                        ? responseData.students_helped
                        : 0}
                    </p>
                  </div>
                  <div className="bg-green-100 rounded-md p-4 ">
                    <h3 className="text-lg font-semibold">Lessons Created</h3>
                    <p className="text-3xl font-bold">
                      {responseData.lessons_created
                        ? responseData.lessons_created
                        : 0}
                    </p>
                  </div>
                  <div className="bg-purple-100 rounded-md p-4">
                    <h3 className="text-lg font-semibold">Average Score</h3>
                    <p className="text-3xl font-bold">
                      {responseData.average_score
                        ? responseData.average_score
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>

        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Recent Activity</h2>
            <ul className="space-y-4">
              {responseData?.recent_activity?.map((activity, index) => {
                const newName =
                  activity.name.substring(0, 60) +
                  (activity.name.length > 60 ? "..." : "");
                return (
                  <li key={index} className="flex items-center">
                    <Clock className="w-5 h-5 mr-2 text-gray-400" />
                    <div>
                      <p className="font-semibold">{newName}</p>
                      <p className="text-sm text-gray-600">{activity.time}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-2xl font-bold mb-4">Upcoming Events</h2>
            <div className="text-center text-xl font-semibold">
              No Events Found
            </div>
            {/* <ul className="space-y-4">
              {upcomingEvents.map((event, index) => (
                <li key={index} className="flex items-center">
                  <Calendar className="w-5 h-5 mr-2 text-gray-400" />
                  <div>
                    <p className="font-semibold">{event.title}</p>
                    <p className="text-sm text-gray-600">{event.date}</p>
                  </div>
                </li>
              ))}
            </ul> */}
          </div>
        </div>
      </div>
      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <a
              href="https://drive.google.com/file/d/1urzhyO5vzWwY8DAlaP6dPqWvlFlPxgKT/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </a>
            <a
              href="https://drive.google.com/file/d/1xI82JEiNX0VnOwXx5vul2l2ZxhNTlNBE/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </footer>
      <AnimatePresence>
        {showNotifications && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className="fixed top-20 right-4 w-80 bg-white rounded-lg shadow-xl p-4"
          >
            <h3 className="text-lg font-semibold mb-2">Notifications</h3>
            <ul className="space-y-2">
              <li className="text-sm">New assignment submitted by John Doe</li>
              <li className="text-sm">
                Reminder: Staff meeting tomorrow at 10 AM
              </li>
              <li className="text-sm">
                3 students need grade review for Math Quiz 3
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
