import React from "react";
import { Link } from "react-router-dom";

export default function Pricing() {
  return (
    <div>
      <div className="flex flex-col justify-center px-4 ">
        <h1 className="pricing_custom text-center text-2xl font-bold mb-4">
          <strong>Simple, transparent pricing</strong>
        </h1>
        <p className="text-center max-w-lg mx-auto mb-8">
          Free for educators and subscription levels to fit your needs. Over
          5,000 school and district partners and growing - the award-winning,
          most loved, and most used AI platform in the world for education.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* PropPilot Free Plan */}
          <div>
            <div id="w-n-tee" className="block-y78"></div>
            <div className="block-4wy p-6 rounded-lg shadow-md">
              <h2 className="title-ost text-xl font-semibold mb-2">
                PropPilot Free
              </h2>
              <div className="block-x85 text-3xl font-bold mb-4">$0</div>
              <div className="block-ob6 mb-4">
                The most used and{" "}
                {/* <a href="https://love.PropPilot.ai/all" className="link-mdp">loved</a> */}{" "}
                AI platform for educators and students.
              </div>
              <Link
                to="login"
                className="button-9oy button-gbo block text-center bg-blue-500 text-white py-2 rounded-md hover:bg-indigo-600 hover:border-indigo-600 mb-4"
              >
                Sign Up Free
              </Link>
              <div className="block-lzq mb-4">Free for educators...</div>
              <ul className="list-sgd list-disc pl-6">
                <li>
                  All 70+ standards based, time saving AI tools for educators
                  and growing
                </li>
                <li>Raina, our AI chatbot built specifically for education</li>
                <li>
                  All 40+ educator monitored tools for students that build AI
                  literacy and skills
                </li>
                <li>
                  AI Slides generation with Presentation Generator exportable to
                  Google Slides
                </li>
                <li>
                  AI Image generation for educators and students powered by
                  Adobe
                </li>
              </ul>
            </div>
          </div>

          {/* PropPilot Plus Plan */}
          <div>
            <div id="w-n-by7" className="block-y78 pur-588"></div>
            <div className="block-4wy p-6 rounded-lg shadow-md">
              <h2 className="title-ost text-xl font-semibold mb-2">
                PropPilot Plus
              </h2>
              <div className="block-x85 text-3xl font-bold mb-1">$99.96</div>
              <div className="block-hyi text-sm text-gray-600 mb-4">/year</div>
              <div className="block-ob6 mb-4">
                For educators who want unlimited access and additional features.
              </div>
              <Link
                to="/login"
                className="button-9oy button-gbo block text-center bg-blue-500 text-white py-2 rounded-md hover:bg-indigo-600 hover:border-indigo-600 mb-4"
              >
                Start a Free Trial
              </Link>
              <div className="block-lzq mb-4">Everything in Free, plus...</div>
              <ul className="list-sgd list-disc pl-6">
                <li>
                  Unlimited AI generations and ability to continue threads with
                  Raina in tools
                </li>
                <li>
                  Unlimited AI Slides generation with Presentation Generator
                  exportable to Google Slides
                </li>
                <li>Unlimited student rooms and usage</li>
                <li>Unlimited output history</li>
                <li>
                  1-Click exports to popular LMS like Google &amp; Microsoft
                </li>
                <li>Early access to new features and tools</li>
              </ul>
            </div>
          </div>

          {/* PropPilot Enterprise Plan */}
          <div>
            <div id="w-n-973" className="block-y78 blu-3qv"></div>
            <div className="block-4wy p-6 rounded-lg shadow-md">
              <h2 className="title-ost text-xl font-semibold mb-2">
                PropPilot Enterprise{" "}
                <sup className="gra-i9j text-sm text-red-500"> Best Value </sup>
              </h2>
              <div className="block-x85 text-3xl font-bold mb-4">Custom</div>
              <div className="block-ob6 mb-4">
                Responsible AI for schools, districts, and institutions.
              </div>
              <a
                href="#"
                className="button-9oy button-gbo block text-center bg-blue-500 text-white py-2 rounded-md hover:bg-indigo-600 hover:border-indigo-600 mb-4"
              >
                Request a Quote
              </a>
              <div className="block-lzq mb-4">Everything in Plus, and...</div>
              <ul className="list-sgd list-disc pl-6">
                <li>
                  School and district level data analysis tools to track teacher
                  and student usage
                </li>
                <li>Advanced moderation and monitoring for student safety</li>
                <li>Custom Data Privacy Agreement (DPA)</li>
                <li>Single sign-on (SSO) for students &amp; educators</li>
                <li>Dedicated support and professional learning options</li>
                <li>Platform customizations for school / district context</li>
                <li>Discounts for full school or district licenses</li>
                <li>
                  Safety &amp; Privacy: FERPA, COPPA, and GDPR, Ed-Law 2D
                  Compliant and more
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
