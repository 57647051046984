import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronRight, ChevronLeft, X } from "lucide-react";

const slides = [
  {
    title: "Welcome to ProfPilot",
    description:
      "Your AI-powered teaching assistant is ready to help you streamline your work and enhance your students' learning experience. Let's take a quick tour of the key features.",
    highlight: { top: "0%", left: "0%", width: "100%", height: "15%" },
  },
  {
    title: "Quick Stats",
    description:
      "View your impact at a glance: students helped, lessons created, and average scores. These metrics help you track your progress and effectiveness.",
    highlight: { top: "15%", left: "0%", width: "100%", height: "20%" },
  },
  {
    title: "AI-Powered Features",
    description:
      "Access powerful tools like the Assignment Grader, Worksheet Generator, and Lesson Planner. These AI-driven features save you time and enhance your teaching materials.",
    highlight: { top: "35%", left: "0%", width: "100%", height: "30%" },
  },
  {
    title: "Recent Activity",
    description:
      "Stay up-to-date with your latest actions and student submissions. This section provides a quick overview of your recent interactions within ProfPilot.",
    highlight: { top: "65%", left: "0%", width: "50%", height: "35%" },
  },
  {
    title: "Upcoming Events",
    description:
      "Never miss an important date with your personal event calendar. Keep track of meetings, deadlines, and other important events all in one place.",
    highlight: { top: "65%", left: "50%", width: "50%", height: "35%" },
  },
];

export default function Walkthrough() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
      localStorage.setItem("walkthrough", "walkthrugh");
    } else {
      console.log("hdhdbdjjfsd");

      setIsVisible(false);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="relative w-full h-full max-w-7xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentSlide}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute inset-0"
          >
            {/* <div
              className="absolute bg-blue-500 bg-opacity-20 border-4 border-blue-500 rounded-lg"
              style={slides[currentSlide].highlight}
            ></div> */}
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 bg-white rounded-lg shadow-lg p-6 w-[480px]">
              <h2 className="text-2xl font-bold mb-2 text-blue-600">
                {slides[currentSlide].title}
              </h2>
              <p className="text-gray-600 mb-4">
                {slides[currentSlide].description}
              </p>
              <div className="flex justify-between items-center">
                <button
                  onClick={prevSlide}
                  disabled={currentSlide === 0}
                  className="p-2 rounded-full bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  aria-label="Previous slide"
                >
                  <ChevronLeft className="w-6 h-6" />
                </button>
                <span className="text-gray-500">
                  {currentSlide + 1} / {slides.length}
                </span>
                {currentSlide === slides.length - 1 ? (
                  <button
                    onClick={() => setIsVisible(false)}
                    className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center"
                  >
                    Finish <X className="w-5 h-5 ml-1" />
                  </button>
                ) : (
                  <button
                    onClick={nextSlide}
                    className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center"
                  >
                    Next <ChevronRight className="w-5 h-5 ml-1" />
                  </button>
                )}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}
