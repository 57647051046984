// src/components/CheckoutForm.js
import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const PaymentForm = ({ success }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setErrorMessage("");
  }, [name, addressLine1, addressLine2, country, city, zipCode]);
  const apiCalling = async (id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/make-payment`,
        {
          id: id,
          subscription_plan: "Yearly",
          address_line1: addressLine1,
          address_line2: addressLine2,
          city: city,
          country: country,
          postal_code: zipCode,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data.status);
      if (response.data.status) {
        localStorage.setItem("payment-status", true);
        success(response.data.msg);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.error(error.response.data.message);
      setErrorMessage(error.response.data.message);
      console.log("error", error.response.data.message);
    }
  };

  const DARK_CARD_OPTIONS = {
    // style: {
    //   base: {
    //     color: "black",
    //     "::placeholder": {
    //       background: "white",
    //     },
    //     ":-webkit-autofill": {
    //       background: "white",
    //     },
    //   },
    // },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (
      !name ||
      !addressLine1 ||
      !addressLine2 ||
      !country ||
      !city ||
      !zipCode
    ) {
      setErrorMessage("All field are Required");
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      // console.log("PaymentMethod created:", paymentMethod);

      apiCalling(paymentMethod.id);
    }
  };

  return (
    <div>
      {/* <ToastContainer position="top-center" /> */}
      <form
        onSubmit={handleSubmit}
        className="max-w-md mx-auto p-6 bg-white rounded-md space-y-4"
      >
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Card Number
          </label>
          <div className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus-within:border-indigo-500">
            <CardNumberElement className="w-full" options={DARK_CARD_OPTIONS} />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Expiry Date
            </label>
            <div className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus-within:border-indigo-500">
              <CardExpiryElement
                className="w-full"
                options={DARK_CARD_OPTIONS}
              />
            </div>
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              CVC
            </label>
            <div className="mt-1 p-2 border border-gray-300 rounded-md shadow-sm focus-within:border-indigo-500">
              <CardCvcElement className="w-full" options={DARK_CARD_OPTIONS} />
            </div>
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Address Line 1
            </label>
            <input
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Address Line 2
            </label>
            <input
              type="text"
              value={addressLine2}
              onChange={(e) => setAddressLine2(e.target.value)}
              className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div className="flex space-x-4">
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              City
            </label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
          <div className="w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Country
            </label>
            <input
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700">
            Zip Code
          </label>
          <input
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            className="mt-1 block w-full p-1 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>

        {errorMessage && (
          <div className="text-red-500 text-sm">{errorMessage}</div>
        )}
        <button
          type="submit"
          disabled={!stripe || loading}
          className="w-full mt-4 px-4 py-2 bg-indigo-600 text-white font-semibold rounded-md shadow hover:bg-indigo-700 disabled:bg-indigo-400"
        >
          {loading ? "Processing..." : "Pay Now"}
        </button>
      </form>
    </div>
  );
};

export default PaymentForm;
