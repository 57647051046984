import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import dummyUser from "../../assets/default_user.png";
import {
  FileText,
  BookOpen,
  CreditCard,
  MessageSquare,
  User,
  LogOut,
  Bell,
  Settings,
  ChevronRight,
  File,
} from "lucide-react";
import { Link } from "react-router-dom";
import axios from "axios";
import { signOut } from "firebase/auth";
import { auth } from "src/firebase";
import AfterLoginLogo from "../AfterLoginLogo";
import Chatbot from "./Chatbot";
import Notes from "./Notes";
import Summary from "./Summary";
import FlashCards from "./Flashcards";

const resources = [
  { icon: <FileText className="w-6 h-6" />, title: "Notes", color: "blue" },
  {
    icon: <BookOpen className="w-6 h-6" />,
    title: "Summaries",
    color: "green",
  },
  {
    icon: <CreditCard className="w-6 h-6" />,
    title: "Flashcards",
    color: "purple",
  },
  {
    icon: <MessageSquare className="w-6 h-6" />,
    title: "Chatbot",
    color: "pink",
  },
];
export default function StudentDashboard() {
  const [showNotifications, setShowNotifications] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedResource, setSelectedResource] = useState(null);
  const [loader, setLoader] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [personalDetail, setPersonalDetail] = useState({
    className: "",
    username: "",
  });
  const getDashboardData = async () => {
    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/dashboard`,
        {
          class_code: localStorage.getItem("class_code"),
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.data.status) {
        setCourseList(response.data.data.course_materials);
        console.log("djjdfsdf0", response.data.data);
        setPersonalDetail({
          className: response.data.data.class_name,
          username: response.data.data.username,
        });
        // setClassName(response.data.data.class_name);
        setLoader(false);
      }
    } catch (err) {
      console.log("err", err.message);
      // setLoader(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const logOutApiCalling = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/logout`, {
        headers: {
          authorization: localStorage.getItem("token"),
        },
      });
      localStorage.removeItem("token");
      localStorage.removeItem("user-token");
      localStorage.removeItem("user-type");
      localStorage.removeItem("class_code");

      window.location.href = "/";
    } catch (error) {
      console.log("error", error.message);
    }
  };
  const logOutFn = async () => {
    await signOut(auth)
      .then((res) => {
        console.log("res", res);
      })
      .catch((error) => {
        // An error happened.
      });
    logOutApiCalling();
  };

  const userData = {
    name: personalDetail.username,
    role: "Student",
    avatar: "/placeholder.svg?height=128&width=128",
    course: personalDetail.className,
  };

  const selectFileFn = async (pdf) => {
    // /active-assignment
    setSelectedResource("");
    setSelectedPdf(pdf);
    try {
      setLoader(true);
      const respone = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/active-assignment`,
        {
          file_id: pdf.file_id,
          class_code: localStorage.getItem("class_code"),
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
    } catch (err) {
      console.log("err", err.message);
    }
  };
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <header className="bg-blue-600 text-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <AfterLoginLogo />
          </div>
          <nav className="flex items-center space-x-4">
            <Link
              href="/profile"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              <User className="w-6 h-6" />
              <span className="sr-only">Profile</span>
            </Link>
            <button
              onClick={logOutFn}
              className="hover:text-blue-200 transition-colors duration-200"
            >
              <LogOut className="w-6 h-6" />
              <span className="sr-only">Logout</span>
            </button>
          </nav>
        </div>
      </header>

      {/* Updated to flex-grow with max width */}
      <main className="flex-grow max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="mb-8 bg-white rounded-lg shadow-md p-6 flex items-center">
          <img
            src={dummyUser}
            alt={userData.name}
            width={80}
            height={80}
            className="rounded-full mr-6 "
            style={{ objectFit: "contain" }}
          />
          <div>
            <h1 className="text-3xl font-bold text-gray-900 capitalize ">
              Welcome, {userData?.name}!
            </h1>
            <p className="text-gray-600 capitalize  ">{userData?.course}</p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-8">
          <div className="w-full md:w-1/3">
            <h2 className="text-2xl font-bold mb-4">Course Materials</h2>
            <div className="bg-white rounded-lg shadow-md p-4">
              {courseList?.length ? (
                courseList?.map((pdf, key) => {
                  function shortenFileName(fileName) {
                    const maxLengthStart = 25;
                    const maxLengthEnd = 10;

                    if (fileName?.length <= maxLengthStart + maxLengthEnd) {
                      return fileName;
                    }

                    // Create the shortened version of the file name
                    const shortenedName =
                      fileName?.substring(0, maxLengthStart) + // Start part
                      "..." + // Hidden middle part
                      fileName?.substring(fileName?.length - maxLengthEnd); // End part

                    return shortenedName;
                  }
                  const shortenedName = shortenFileName(pdf?.original_name);
                  return (
                    <div
                      key={key}
                      className={`flex items-center p-3 rounded-md cursor-pointer ${
                        selectedPdf && selectedPdf?.file_id === pdf.file_id
                          ? "bg-blue-100"
                          : "hover:bg-gray-100"
                      }`}
                      onClick={() => selectFileFn(pdf)}
                    >
                      <File className="w-5 h-5 mr-3 text-blue-600" />
                      <div>
                        <p className="font-medium wrap-text">{shortenedName}</p>
                        <p className="text-sm text-gray-500">
                          {pdf?.pages} pages
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="text-center text-xl text-gray-500">
                  Course Materials are not available.
                </div>
              )}
            </div>
          </div>
          <div className="w-full md:w-2/3">
            {selectedPdf ? (
              <div>
                <h2 className="text-2xl font-bold mb-4">{selectedPdf.name}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  {resources.map((resource, index) => (
                    <motion.div
                      key={index}
                      className={`bg-white rounded-lg shadow-md p-6 flex items-center cursor-pointer ${
                        selectedResource === resource.title
                          ? "ring-2 ring-blue-500"
                          : ""
                      }`}
                      whileHover={{ scale: 1.05 }}
                      transition={{ duration: 0.2 }}
                      onClick={() => setSelectedResource(resource.title)}
                    >
                      <div
                        className={`p-3 rounded-full mr-4 bg-${resource.color}-100`}
                      >
                        {resource.icon}
                      </div>
                      <h3 className="text-lg font-semibold">
                        {resource.title}
                      </h3>
                    </motion.div>
                  ))}
                </div>
                {selectedResource &&
                  (selectedResource == "Chatbot" ? (
                    //
                    <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                      <h3 className="text-xl font-bold mb-4">
                        {selectedResource}
                      </h3>
                      <Chatbot file_id={selectedPdf.file_id} />
                    </div>
                  ) : selectedResource == "Notes" ? (
                    <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                      <h3 className="text-xl font-bold mb-4">
                        {selectedResource}
                      </h3>
                      <Notes file_id={selectedPdf.file_id} />
                    </div>
                  ) : selectedResource == "Summaries" ? (
                    <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                      <h3 className="text-xl font-bold mb-4">
                        {selectedResource}
                      </h3>
                      <Summary file_id={selectedPdf.file_id} />
                    </div>
                  ) : selectedResource == "Flashcards" ? (
                    <div className="mt-8 bg-white rounded-lg shadow-md p-6">
                      <h3 className="text-xl font-bold mb-4">
                        {selectedResource}
                      </h3>
                      <FlashCards file_id={selectedPdf.file_id} />
                    </div>
                  ) : (
                    ""
                  ))}
              </div>
            ) : (
              <div className="flex items-center justify-center h-full bg-white rounded-lg shadow-md p-6">
                <p className="text-xl text-gray-500">
                  Select a PDF to view study resources
                </p>
              </div>
            )}
          </div>
        </div>
      </main>

      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link
              href="/privacy"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </footer>

      {showNotifications && (
        <div className="fixed top-20 right-4 w-80 bg-white rounded-lg shadow-xl p-4">
          <h3 className="text-lg font-semibold mb-2">Notifications</h3>
          <ul className="space-y-2">
            <li className="text-sm">New notes available for Chapter 4</li>
            <li className="text-sm">Quiz reminder: Cell Biology on Friday</li>
            <li className="text-sm">Your question has been answered</li>
          </ul>
        </div>
      )}
    </div>
  );
}
