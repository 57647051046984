export default function Component() {
  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center space-x-2">
        <svg
          width="50"
          height="50"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50"
            cy="50"
            r="48"
            fill="#2563EB"
            stroke="white"
            strokeWidth="4"
          />
          <path
            d="M30 70L50 30L70 70"
            stroke="white"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="50" cy="50" r="10" fill="white" />
          <path
            d="M38 62L62 62"
            stroke="white"
            strokeWidth="8"
            strokeLinecap="round"
          />
        </svg>
        <span className="text-xl sm:text-4xl font-bold text-black cursor-pointer ">
          ProfPilot
        </span>
      </div>
    </div>
  );
}
