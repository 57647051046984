import axios from "axios";
import React, { useEffect, useState } from "react";
import AfterLoginLogo from "./DashLogo";
import { Link } from "react-router-dom";
import { ArrowLeft, FileText, Settings, LoaderCircle } from "lucide-react";
import { motion } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Profile = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    profession_type: "",
    name_student_call_you: "",
  });
  const [grade, setGrade] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const getApiResponse = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/profile`,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
      if (response.data.status) {
        setUser({
          name: response.data.data.name,
          email: response.data.data.email,
          profession_type: response.data.data.profession_type,
          name_student_call_you: response.data.data.name_student_call_you,
        });
        setGrade(response.data.data.grade_level);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    getApiResponse();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/update-profile`,
        {
          name: user.name,
          name_student_call_you: user.name_student_call_you,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      setLoader(false);
      if (response.data.status) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log("error", error.message);

      setLoader(false);
    }
  };
  return (
    <>
      {loading ? (
        <div className="min-h-screen flex justify-center items-center">
          <LoaderCircle className="animate-spin" />
        </div>
      ) : (
        <div className="min-h-screen flex flex-col bg-blue-50">
          <ToastContainer position="top-center" />
          <header className="bg-white shadow-sm">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
              <Link
                to="/dashboard"
                className="flex items-center text-blue-600 hover:text-blue-800"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Back to Dashboard
              </Link>
              <div className="flex items-center">
                <AfterLoginLogo />
              </div>
            </div>
          </header>
          <main className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Profile</h1>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-lg shadow-md p-6 mb-8"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-900">
                  Update your profile information.
                </h2>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Name
                  </label>

                  <input
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    id="customSubject"
                    value={user.name}
                    name="name"
                    onChange={handleChange}
                    placeholder=""
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="topic"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Student's call you
                  </label>
                  <input
                    type="text"
                    id="topic"
                    value={user.name_student_call_you}
                    name="name_student_call_you"
                    onChange={handleChange}
                    placeholder="Enter specific topic"
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div className="mt-8 flex justify-end">
                <button
                  onClick={handleSubmit}
                  className="flex items-center px-4 py-2 rounded-md 
             bg-blue-600 text-white hover:bg-blue-700
               transition-colors duration-200"
                >
                  {loader ? (
                    <>
                      <Settings className="w-5 h-5 mr-2 animate-spin" />
                      Generating...
                    </>
                  ) : (
                    <>
                      <FileText className="w-5 h-5 mr-2" />
                      Update Profile
                    </>
                  )}
                </button>
              </div>
            </motion.div>
          </main>
          <footer className="bg-blue-800 text-white py-8 mt-auto">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
              <p className="mb-4 md:mb-0">
                © {new Date().getFullYear()} ProfPilot. All rights reserved.
              </p>
              <div className="space-x-4">
                <a
                  href="https://drive.google.com/file/d/1urzhyO5vzWwY8DAlaP6dPqWvlFlPxgKT/view"
                  target="blank"
                  className="hover:text-blue-200 transition-colors duration-200"
                >
                  Privacy Policy
                </a>
                <a
                  href="https://drive.google.com/file/d/1xI82JEiNX0VnOwXx5vul2l2ZxhNTlNBE/view"
                  target="blank"
                  className="hover:text-blue-200 transition-colors duration-200"
                >
                  Terms of Service
                </a>
              </div>
            </div>
          </footer>
        </div>
      )}
    </>
  );
};

export default Profile;
