import google from "../assets/Google_Classroom_Logo.png";
import canvas from "../assets/canvas_logo.png";
import blackboard from "../assets/blackboard.jpg";
import userImage from "../assets/default_user.png";
// import AfterLoginLogo from "./AfterLoginLogo";
import pro1 from "../assets/Shuo_Chen.png";
import pro2 from "../assets/Hubert.png";
import React, { useState, useEffect } from "react";
import DashLogo from "./DashLogo";
import {
  motion,
  AnimatePresence,
  useMotionValue,
  useTransform,
  animate,
  useInView,
} from "framer-motion";
import {
  CheckCircle,
  Zap,
  BookOpen,
  Brain,
  Sparkles,
  Rocket,
  Users,
  MessageSquare,
  X,
  Menu,
} from "lucide-react";
// import img from 'next/img'
import { Link } from "react-router-dom";
import logo from "../assets/ProfPilotLogo2.png";
import Footer from "./Footer";
const aiFeatures = ["Grading", "Lesson Planning", "Quiz Creation"];

const AnimatedNumber = ({ value }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const count = useMotionValue(0);
  const rounded = useTransform(count, (latest) => Math.round(latest));

  React.useEffect(() => {
    if (isInView) {
      const controls = animate(count, value, { duration: 2 });
      return controls.stop;
    }
  }, [count, value, isInView]);

  return <motion.span ref={ref}>{rounded}</motion.span>;
};

export default function Component() {
  const [currentFeature, setCurrentFeature] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFeature((prev) => (prev + 1) % aiFeatures.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen bg-blue-600 text-white overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern
              id="grid"
              width="40"
              height="40"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 40 0 L 0 0 0 40"
                fill="none"
                stroke="rgba(255,255,255,0.1)"
                strokeWidth="1"
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />
          {[...Array(50)].map((_, i) => (
            <motion.circle
              key={i}
              cx={Math.random() * 100 + "%"}
              cy={Math.random() * 100 + "%"}
              r="1"
              fill="white"
              initial={{ opacity: 0 }}
              animate={{
                opacity: [0, 1, 0],
                scale: [0.8, 1.2, 0.8],
              }}
              transition={{
                repeat: Infinity,
                duration: Math.random() * 5 + 5,
                ease: "easeInOut",
              }}
            />
          ))}
        </svg>
      </div>

      <header
        className={`relative z-10 ${
          isOpen ? "" : "container mx-auto px-4 py-6"
        } flex justify-between items-center text-white`}
      >
        {!isOpen && (
          <>
            {" "}
            <motion.div
              className="flex items-center space-x-2"
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img src={logo} alt="ProfPilot Logo" width={150} height={150} />
            </motion.div>
            {/* Hamburger Menu for Mobile */}
            <div className="sm:hidden">
              <button id="hamburger" className="" onClick={toggleMenu}>
                <Menu />
              </button>
            </div>
          </>
        )}
        {/* Navigation Menu for Desktop */}
        <nav className="hidden sm:block">
          <ul className="flex space-x-6">
            <motion.li
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              <a
                href="#features"
                className=" hover:text-blue-500 transition-colors"
              >
                Features
              </a>
            </motion.li>
            <motion.li
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <a
                href="#how-it-works"
                className=" hover:text-blue-500 transition-colors"
              >
                How It Works
              </a>
            </motion.li>
            <motion.li
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <a
                href="#testimonials"
                className=" hover:text-blue-500 transition-colors"
              >
                Testimonials
              </a>
            </motion.li>
            <motion.li
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <a
                href="#pricing"
                className=" hover:text-blue-500 transition-colors"
              >
                Pricing
              </a>
            </motion.li>
          </ul>
        </nav>

        {/* Mobile Menu Slider */}
        <motion.nav
          id="mobile-menu"
          initial={{ y: -200, opacity: 0 }} // starts off-screen
          animate={isOpen ? { y: 0, opacity: 1 } : { y: -200, opacity: 0 }} // slides in/out
          transition={{ duration: 0.5 }}
          className={` top-0 left-0 w-full bg-white lg:hidden ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <div
            className="px-4 py-6 text-black flex justify-between items-center"
            onClick={() => setIsOpen(false)}
          >
            <DashLogo />
            <X />
          </div>
          <ul className="flex flex-col items-center space-y-4 py-4 bg-white shadow-lg">
            <motion.li
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <a
                href="#features"
                className="text-gray-800 hover:text-blue-500 transition-colors"
              >
                Features
              </a>
            </motion.li>
            <li>
              <a
                href="#how-it-works"
                className="text-gray-800 hover:text-blue-500 transition-colors"
              >
                How It Works
              </a>
            </li>
            <li>
              <a
                href="#testimonials"
                className="text-gray-800 hover:text-blue-500 transition-colors"
              >
                Testimonials
              </a>
            </li>
            <li>
              <a
                href="#pricing"
                className="text-gray-800 hover:text-blue-500 transition-colors"
              >
                Pricing
              </a>
            </li>
          </ul>
        </motion.nav>
      </header>

      <main className="relative z-10">
        <section className="container mx-auto px-4 py-20 text-center">
          <div
            className={`text-6xl font-bold mb-6 ${isOpen ? "" : "text-white"}`}
            style={{ opacity: `${isOpen ? "-1" : ""}` }}
          >
            Welcome to ProfPilot
          </div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-2xl mb-8 flex justify-center items-center"
          >
            <span>AI-powered assistance for</span>
            <div className="w-64 h-8 relative ml-2 overflow-hidden">
              <AnimatePresence>
                <motion.span
                  key={currentFeature}
                  className="absolute left-0 right-0 text-blue-200 font-bold whitespace-nowrap"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -20, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  {aiFeatures[currentFeature]}
                </motion.span>
              </AnimatePresence>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
            className="flex justify-center space-x-4"
          >
            <Link
              to="/login"
              className="bg-white text-blue-600 px-8 py-4 rounded-full font-semibold hover:bg-blue-100 transition-colors transform hover:scale-105 duration-200 shadow-lg"
            >
              Get Started
            </Link>
            <a
              href="#learn-more"
              className="bg-blue-700 text-white px-8 py-4 rounded-full font-semibold hover:bg-blue-800 transition-colors transform hover:scale-105 duration-200 shadow-lg"
            >
              Learn More
            </a>
          </motion.div>
        </section>

        <section id="features" className="container mx-auto px-4 py-20">
          <h2 className="text-4xl font-bold text-center mb-12 text-white">
            Supercharge Your Teaching
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <CheckCircle className="w-12 h-12 mb-4 text-black" />,
                title: "Smart Grading",
                description:
                  "AI-powered grading that saves time and provides detailed feedback",
              },
              {
                icon: <BookOpen className="w-12 h-12 mb-4 text-black" />,
                title: "Interactive Lessons",
                description: "Create engaging, adaptive lesson plans with ease",
              },
              {
                icon: <Zap className="w-12 h-12 mb-4 text-black" />,
                title: "Instant Quizzes",
                description:
                  "Generate relevant quizzes tailored to your curriculum",
              },
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white p-8 rounded-xl shadow-xl text-center transform hover:scale-105 duration-200"
              >
                <div className="flex justify-center text-black">
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-black">
                  {feature.title}
                </h3>
                <p className="text-black">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </section>

        <section id="how-it-works" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12 text-black">
              How ProfPilot Works Its Magic
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: <Sparkles className="w-12 h-12 text-blue-200" />,
                  title: "Input Your Content",
                  description:
                    "Upload assignments, lesson plans, or quiz topics",
                },
                {
                  icon: <Brain className="w-12 h-12 text-blue-200" />,
                  title: "AI Processing",
                  description:
                    "Our advanced AI analyzes and enhances your content",
                },
                {
                  icon: <Rocket className="w-12 h-12 text-blue-200" />,
                  title: "Elevate Your Teaching",
                  description:
                    "Use AI-powered insights to supercharge your lessons",
                },
              ].map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="flex flex-col items-center text-center bg-blue-600 p-8 rounded-xl shadow-xl transform hover:scale-105 duration-200"
                >
                  <div className="mb-4">{step.icon}</div>
                  <h3 className="text-2xl font-semibold mb-4 text-white">
                    {step.title}
                  </h3>
                  <p className="text-blue-100">{step.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="integrations" className="py-20 bg-bg-blue-700">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12 text-white">
              Print or Export to...
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { text: "Google Classroom", link: google },
                { text: "Canvas", link: canvas },
                { text: "Blackboard", link: blackboard },
              ].map((platform, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="flex-col justify-center items-center  text-2xl bg-white p-4 rounded-xl shadow-xl text-center text-black  font-bold transform hover:scale-105 duration-200"
                >
                  <div className="flex justify-center">
                    <img
                      src={platform.link}
                      alt="logo"
                      style={{
                        height: "60px",
                        width: "80px",
                        objectFit: "contain",
                      }}
                      className=""
                    />{" "}
                  </div>
                  <span className="">{platform.text}</span>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12 text-black">
              Trusted by
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  icon: <Users className="w-12 h-12 mb-4 text-blue-200" />,
                  number: 500000,
                  label: "Students on Platform",
                },
                {
                  icon: <BookOpen className="w-12 h-12 mb-4 text-blue-200" />,
                  number: 500,
                  label: "Universities have students on board",
                },
                {
                  icon: (
                    <MessageSquare className="w-12 h-12 mb-4 text-blue-200" />
                  ),
                  number: 1500000,
                  label: "Questions Answered",
                },
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-blue-600 p-8 rounded-xl shadow-xl text-center"
                >
                  <div className="flex justify-center">{stat.icon}</div>
                  <div className="text-4xl font-bold text-white">
                    <AnimatedNumber value={stat.number} />
                  </div>
                  <p className="text-xl text-blue-100">{stat.label}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="testimonials" className="py-20 bg-blue-600">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12 text-white">
              Educators love us
            </h2>
            <div className="grid md:grid-cols-2 gap-8">
              {[
                {
                  quote:
                    "This is a wonderful example of generative AI leveling the playing field to accelerate more innovation. The AI Tutor helps answer basic questions of students and allows me to focus on student education with great speed allowing me to spend most time teaching.",
                  author: "Prof Shuo Chen",
                  title: "Faculty, UC Berkeley, Stanford",
                  img: pro1,
                },
                {
                  quote:
                    "It's a life saver. Working as a Teaching Assistant at UC Berkeley's Mechanical Engineering division,I'm constantly grading and resolving student queries about course materials that run into many hours each week. With ProfPilot, I am able to gather up information and double check my solutions much more quickly, and allows my students to get quick feedback and problem-solving support. Brilliant!",
                  author: "Hubert Liu",
                  title: "UC Berkeley TA with 200 students",
                  img: pro2,
                },
              ].map((testimonial, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-white p-8 rounded-xl shadow-xl flex flex-col md:flex-row items-center"
                >
                  <div className="md:w-1/3 mb-4 md:mb-0 md:mr-4">
                    <img
                      src={testimonial.img}
                      alt={testimonial.author}
                      width={150}
                      height={150}
                      className="rounded-full text-black" //rounded-full
                    />
                  </div>
                  <div className="md:w-2/3">
                    <MessageSquare className="w-12 h-12 mb-4 text-black" />
                    <p className="mb-4 italic text-black">
                      "{testimonial.quote}"
                    </p>
                    <p className="font-semibold text-black">
                      {testimonial.author}
                    </p>
                    <p className="text-sm text-black">{testimonial.title}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="pricing" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold text-center mb-12 text-black">
              Choose Your ProfPilot Journey
            </h2>
            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  name: "ProfPilot Starter",
                  price: "Free",
                  url: "/login",
                  features: [
                    "Up to 7 generations per month",
                    "Access to basic AI tools",
                    "Community support",
                  ],
                },
                {
                  name: "ProfPilot Pro",
                  price: "$79.99",
                  subtext: "/year",
                  url: "/login",
                  features: [
                    "Unlimited AI generations",
                    "Priority processing",
                    "Advanced analytics",
                    "Email support",
                  ],
                },
                {
                  name: "ProfPilot Enterprise",
                  price: "Custom",
                  url: "https://calendly.com/shubhan-cr0l/30min",
                  features: [
                    "Custom AI model training",
                    "API access",
                    "Dedicated account manager",
                    "24/7 premium support",
                  ],
                },
              ].map((plan, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="bg-blue-700 p-8 rounded-xl shadow-xl flex flex-col justify-between transform hover:scale-105 duration-200"
                >
                  <div>
                    <h3 className="text-2xl font-semibold mb-4 text-white">
                      {plan.name}
                    </h3>
                    <p className="text-4xl font-bold mb-1 text-blue-200">
                      {plan.price}
                    </p>
                    {plan.subtext && (
                      <p className="text-sm text-blue-300 mb-6">
                        {plan.subtext}
                      </p>
                    )}
                    <ul className="mb-8">
                      {plan.features.map((feature, i) => (
                        <li
                          key={i}
                          className="flex items-center mb-2 text-blue-100"
                        >
                          <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Link
                    to={plan.url}
                    target={plan.url == "/login" ? "" : "_blank"}
                    className="block w-full text-center bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition-colors transform hover:scale-105 duration-200"
                  >
                    {plan.price === "Free"
                      ? "Get Started"
                      : plan.price === "Custom"
                      ? "Contact Sales"
                      : "Start Free Trial"}
                  </Link>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section id="cta" className="py-20 bg-blue-700 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-8">
              Ready to Revolutionize Your Teaching?
            </h2>
            <p className="text-xl mb-8 text-blue-100">
              Join thousands of educators who are already using ProfPilot to
              transform their classrooms.
            </p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Link
                to="/login"
                className="bg-white text-blue-600 px-8 py-4 rounded-full font-semibold hover:bg-blue-100 transition-colors inline-block transform hover:scale-105 duration-200 shadow-lg"
              >
                Start Your Free Trial
              </Link>
            </motion.div>
          </div>
        </section>
      </main>
      <section className=" bg-blue-700 text-white">
        <Footer />
      </section>
    </div>
  );
}
