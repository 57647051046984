import axios from "axios";
import React, { useEffect, useState } from "react";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { LoaderCircle } from "lucide-react";
import "draft-js/dist/Draft.css";
import { mdToDraftjs } from "draftjs-md-converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Notes = ({ file_id }) => {
  const [loader, setLoader] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const getResponse = async () => {
    try {
      setLoader(true);
      const respone = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/assignment-flashcard`,
        {
          file_id: file_id,
          class_code: localStorage.getItem("class_code"),
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );

      console.log("resposne", respone.data.data);
      if (respone.data.status) {
        setLoader(false);
        setResponseData(respone.data.data);
        const rawData = mdToDraftjs(respone.data.data);
        const contentState = convertFromRaw(rawData);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getResponse();
  }, []);

  return (
    <div>
      <ToastContainer position="top-center" />
      {loader ? (
        <div className="spinner-container flex justify-center items-center">
          <LoaderCircle className="spinner" size={25} />
        </div>
      ) : responseData.length ? (
        <div className="handle_custom_height">
          <Editor editorState={editorState} onChange={setEditorState} />
        </div>
      ) : (
        <div className="text-bold text-xl text-center">Data not Found</div>
      )}
    </div>
  );
};

export default Notes;
