// src/App.js
import "./app/globals.css";
import React, { useEffect, useState } from "react";
import WorksheetGenerator from "./components/WorksheetGenerator";
import LessonPlanner from "./components/LessonPlanner";
import LandingPage from "./components/LandingPage";
import GetStarted from "./components/GetStarted";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import StudentDashboard from "./components/student/StudentComp";
import AssignmentGrader from "./components/AssignmentGrader";
import UserPersonal from "./components/UserPersonal";
import "./App.css";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Pricing from "./components/Pricing";
import NotFound from "./components/NotFound";
import DeployToStudent from "./components/DeployToStudent";
import DeployToStudent2 from "./components/DeployToStudent2";
import DeployToStudent3 from "./components/DeployToStudent3";
import AfterLogin from "./components/AfterLogin";
import StudentPersonal from "./components/StudentPersonal";
import PaymentComponent from "./components/PaymentComponent";
import Profile from "./components/Profile";
const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  return token ? children : <Navigate to="/login" />;
};

function App() {
  const [token, setToken] = useState("");
  const [userType, setUserType] = useState("teacher");
  const [signUpData, setSignUpData] = useState({
    signUpemail: "",
    SignUppassword: "",
    confirmPassword: "",
  });
  const [loginData, setLoginData] = useState({
    loginEmail: "",
    loginPassword: "",
  });
  useEffect(() => {
    console.log("app", userType);

    setToken(localStorage.getItem("token"));
    const users = localStorage.getItem("user-type");
    if (users) {
      console.log("user validation", users);

      setUserType(localStorage.getItem("user-type"));
    }
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={token ? <Navigate to="/dashboard" /> : <LandingPage />}
        />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/:code"
          element={
            <Login
              setUserType={setUserType}
              userType={userType}
              setSignUpData={setSignUpData}
              signUpData={signUpData}
              setLoginData={setLoginData}
              loginData={loginData}
            />
          }
        />

        <Route
          path="/login"
          element={
            token ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login
                setUserType={setUserType}
                userType={userType}
                setSignUpData={setSignUpData}
                signUpData={signUpData}
                setLoginData={setLoginData}
                loginData={loginData}
              /> // getStarted
            )
          }
        />
        <Route
          path="/user-personal"
          element={
            userType === "teacher" ? (
              <UserPersonal
                setSignUpData={setSignUpData}
                signUpData={signUpData}
                setLoginData={setLoginData}
                loginData={loginData}
              />
            ) : (
              <Navigate to="*" />
            )
          }
        />
        <Route
          path="/student-personal"
          element={
            userType === "student" ? (
              <StudentPersonal
                setSignUpData={setSignUpData}
                signUpData={signUpData}
                setLoginData={setLoginData}
                loginData={loginData}
              />
            ) : (
              <Navigate to="*" />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/worksheet-generator"
          element={
            <ProtectedRoute>
              <WorksheetGenerator />
            </ProtectedRoute>
          }
        />

        <Route
          path="/lesson-planner"
          element={
            <ProtectedRoute>
              <LessonPlanner />
            </ProtectedRoute>
          }
        />
        <Route path="/pricing" element={<Pricing />} />
        <Route
          path="/assignment-grader"
          element={
            <ProtectedRoute>
              <AssignmentGrader />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deploy-student"
          element={
            <ProtectedRoute>
              <DeployToStudent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deploy-student-detail"
          element={
            <ProtectedRoute>
              <DeployToStudent2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deploy-student-code"
          element={
            <ProtectedRoute>
              <DeployToStudent3 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route path="after-login" element={<AfterLogin />} />
        <Route path="payment" element={<PaymentComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
