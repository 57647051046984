import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
// import AfterLoginLogo from "./DashLogo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../@/components//ui/payment-dailog";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import {
  ArrowLeft,
  Upload,
  FileText,
  Wand2,
  Download,
  MessageSquare,
  AlertTriangle,
  LoaderCircle,
  DollarSign,
  CreditCard,
  XCircle,
  Clock,
} from "lucide-react";
import { Link } from "react-router-dom";
import AfterLoginLogo from "./DashLogo";
import axios from "axios";
import { Button } from "src/@/components/ui/button";
export default function AssignmentGenerator() {
  const [selectedAssignments, setSelectedAssignments] = useState([]);
  const [selectedRubric, setSelectedRubric] = useState(null);
  const [isGeneratingRubric, setIsGeneratingRubric] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [gradeLoader, setGradeLoader] = useState(false);
  const [gradeResponse, setGradeResponse] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [rubricPdfUrl, setRubricPdfUrl] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const gradedAssignment = {
    title: "",
    studentName: "",
    score: gradeResponse.score,
    totalPoints: gradeResponse.totalPoints,
    feedback: [
      {
        type: "positive",
        text: "Excellent introduction that clearly states the thesis.",
        position: { top: "10%", left: "5%" },
      },
      {
        type: "suggestion",
        text: "Consider adding more statistical evidence to support your arguments.",
        position: { top: "30%", left: "70%" },
      },
      {
        type: "correction",
        text: 'Check the spelling of "enviromental" - should be "environmental".',
        position: { top: "50%", left: "10%" },
      },
      {
        type: "positive",
        text: "Strong conclusion that effectively summarizes your main points.",
        position: { top: "80%", left: "60%" },
      },
    ],
    rubricScores: [
      { criterion: "Content", score: 18, outOf: 20 },
      { criterion: "Organization", score: 17, outOf: 20 },
      { criterion: "Grammar and Spelling", score: 16, outOf: 20 },
      { criterion: "Critical Thinking", score: 17, outOf: 20 },
      { criterion: "Citations and References", score: 17, outOf: 20 },
    ],
  };

  const handleAssignmentUpload = (event) => {
    if (event.target.files) {
      setSelectedAssignments(event.target.files[0]);
    }
  };
  const handleRubricUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedRubric(event.target.files[0]);
    }
  };

  const downloadPdf = async (url, filename) => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });

      const fileUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Error downloading the PDF:", error.message);
      toast.error(error.message);
    }
  };
  const openModal = () => setIsOpen(true);
  const handleGenerateRubric = async () => {
    try {
      setIsGeneratingRubric(true);
      const formData = new FormData();
      formData.append("file", selectedAssignments);
      formData.append("moduleType", "Assignment");

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/rubric-generate`,
        formData,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setIsGeneratingRubric(false);
      console.log("response", response.data);
      if (response.data.status) {
        setRubricPdfUrl(response.data.file_url);
      }
    } catch (error) {
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
      setIsGeneratingRubric(false);
    }
  };

  const getGrading = async () => {
    setShowResponse(false);
    try {
      setGradeLoader(true);
      const formData = new FormData();
      formData.append("file", selectedAssignments);
      formData.append("rubric_file", selectedRubric);
      formData.append("moduleType", "Assignment");

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/assignment-grader`,
        formData,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      console.log("response", response.data.data);
      if (response.data.status) {
        setGradeResponse(response.data.data);
        setGradeLoader(false);
        setShowResponse(true);
      }
      setGradeLoader(false);
    } catch (error) {
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
      setGradeLoader(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-blue-50">
      <ToastContainer position="top-center" />
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          <Link
            to="/dashboard"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </Link>
          <div className="flex items-center">
            <AfterLoginLogo />
          </div>
        </div>
      </header>
      <main className=" w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Assignment Grader
        </h1>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-lg shadow-md p-6 mb-8"
        >
          <h2 className="text-xl font-semibold text-gray-900 mb-4">
            Upload Assignments
          </h2>
          <p className="text-gray-600 mb-4">Select assignments to grade</p>
          <div className="flex items-center justify-between">
            <label className="flex items-center px-4 py-2 bg-white text-blue-600 rounded-md border border-blue-600 cursor-pointer hover:bg-blue-50 transition-colors duration-200">
              <Upload className="w-5 h-5 mr-2" />
              Choose Files
              <input
                type="file"
                multiple
                onChange={handleAssignmentUpload}
                className="hidden"
              />
            </label>
          </div>
          <div>
            {" "}
            {selectedAssignments.name && (
              <p className="mt-4 text-green-600">
                Assignment selected: {selectedAssignments.name}
              </p>
            )}
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-white rounded-lg shadow-md p-6 mb-8"
        >
          <h2 className="text-xl font-semibold text-gray-900 mb-4">Rubric</h2>
          <p className="text-gray-600 mb-4">
            Upload an existing rubric or generate one with AI
          </p>
          <div className="flex items-center space-x-4">
            <label className="flex items-center px-4 py-2 bg-white text-blue-600 rounded-md border border-blue-600 cursor-pointer hover:bg-blue-50 transition-colors duration-200">
              <FileText className="w-5 h-5 mr-2" />
              Upload Rubric
              <input
                type="file"
                onChange={handleRubricUpload}
                className="hidden"
              />
            </label>
            <button
              onClick={handleGenerateRubric}
              disabled={isGeneratingRubric}
              className={`flex items-center px-4 py-2 rounded-md ${
                isGeneratingRubric
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              } transition-colors duration-200`}
            >
              <Wand2 className="w-5 h-5 mr-2" />
              {isGeneratingRubric ? "Generating..." : "Generate with AI"}
            </button>
          </div>
          {selectedRubric && (
            <p className="mt-4 text-green-600">
              Rubric selected: {selectedRubric.name}
            </p>
          )}
        </motion.div>
        {rubricPdfUrl && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="bg-white rounded-lg shadow-md p-6 mb-8"
          >
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Generated Rubric
            </h2>
            <div className="pdf-container">
              <Viewer
                fileUrl={rubricPdfUrl}
                plugins={[defaultLayoutPlugin()]}
              />
            </div>
          </motion.div>
        )}
        <div className="text-red-600 mb-5">{errorMsg}</div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <div className="text-red-600 my-3 text-xl text-semibold">{error}</div>
          {!gradeLoader ? (
            <button
              onClick={getGrading}
              disabled={!selectedAssignments || !selectedRubric}
              className={`w-full px-6 py-3 rounded-md ${
                !selectedAssignments || !selectedRubric
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              } transition-colors duration-200`}
            >
              Start Grading
            </button>
          ) : (
            <button
              className={`w-full px-6 py-3 rounded-md ${
                !selectedAssignments || !selectedRubric
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-blue-600 text-white hover:bg-blue-700"
              } transition-colors duration-200`}
            >
              {" "}
              <div className="spinner-container flex justify-center items-center">
                <LoaderCircle className="spinner" size={25} />
              </div>
            </button>
          )}
        </motion.div>
      </main>

      {showResponse ? (
        <main className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg shadow-md p-6 mb-8"
          >
            <h1 className="text-3xl font-bold text-gray-900 mb-4">
              {gradedAssignment.title}
            </h1>
            <p className="text-xl text-gray-600 mb-6">
              Student: {gradedAssignment.studentName}
            </p>
            <div className="flex items-center justify-between mb-8">
              <div className="text-4xl font-bold text-blue-600">
                Score: {gradedAssignment.score}/{gradedAssignment.totalPoints}
              </div>
              <div className="text-2xl font-semibold text-gray-600">
                {Math.round(
                  (gradedAssignment.score / gradedAssignment.totalPoints) * 100
                )}
                %
              </div>
            </div>

            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Rubric Scores
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
              {gradeResponse.rubricScores.map((item, index) => (
                <div key={index} className="bg-gray-100 rounded-md p-4">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">
                    {item.criterion}
                  </h3>
                  <div className="flex items-center">
                    <div className="w-full bg-gray-300 rounded-full h-2.5 mr-2">
                      <div
                        className="bg-blue-600 h-2.5 rounded-full"
                        style={{ width: `${(item.score / item.outOf) * 100}%` }}
                      ></div>
                    </div>
                    <span className="text-sm font-medium text-gray-600">
                      {item.score}/{item.outOf}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">
              Assignment Preview with Comments
            </h2>
            <div className="relative bg-gray-100 rounded-md p-4  mb-8 mx-h-96 overflow-y-auto">
              {/* This would be replaced with the actual assignment content */}

              {gradeResponse.feedback.map((comment, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: index * 0.2 }}
                  className={`flex  my-2 ${
                    comment.type === "suggestion" ||
                    comment.type === "correction"
                      ? "justify-end"
                      : ""
                  }`}
                >
                  <div
                    className={`p-2 rounded-md shadow-md w-1/2 ${
                      comment.type === "positive"
                        ? "bg-green-100 text-green-800"
                        : comment.type === "suggestion"
                        ? "bg-yellow-100 text-yellow-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    <div className="flex items-start">
                      {comment.type === "positive" && (
                        <MessageSquare className="w-4 h-4 mr-2 mt-1" />
                      )}
                      {comment.type === "suggestion" && (
                        <AlertTriangle className="w-4 h-4 mr-2 mt-1" />
                      )}
                      {comment.type === "correction" && (
                        <AlertTriangle className="w-4 h-4 mr-2 mt-1" />
                      )}
                      <p className="text-sm">{comment.text}</p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </main>
      ) : (
        ""
      )}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-lg bg-white rounded-lg p-6">
          {/* Modal Header */}
          <DialogHeader>
            <DialogTitle className="text-xl text-center font-semibold">
              You have reached the limit of 7 uses for this module
            </DialogTitle>
          </DialogHeader>

          {/* Modal Body */}
          <DialogDescription className="mb-4">
            {/* <p className="text-center text-lg ">Upgrade to our premium plan.</p> */}
            <div className="flex justify-center mt-3">
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="flex items-center outline-none px-4 py-2 mr-5 bg-white text-xl text-blue-600 rounded-md border border-blue-600 cursor-pointer hover:bg-blue-50 transition-colors duration-200 "
              >
                <Clock className="w-5 h-5 mr-2" /> Not Now!
              </button>
              <Link
                to="/payment"
                className="flex items-center bg-blue-600 hover:bg-blue-800 text-white text-xl px-4 py-2 rounded-lg   "
              >
                <CreditCard className="w-5 h-5 mr-2" /> Upgrade Now!
              </Link>
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <a
              href="https://drive.google.com/file/d/1urzhyO5vzWwY8DAlaP6dPqWvlFlPxgKT/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </a>
            <a
              href="https://drive.google.com/file/d/1xI82JEiNX0VnOwXx5vul2l2ZxhNTlNBE/view"
              target="blank"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
