import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  FileText,
  Book,
  CreditCard,
  MessageSquare,
  BarChart,
  Users,
  ArrowLeft,
  Upload,
  Link as LinkIcon,
  Box,
  Trash2,
  Clock,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../@/components//ui/payment-dailog";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import google from "../assets/googlelogo.svg";
import AfterLoginLogo from "./AfterLoginLogo";
import axios from "axios";
import { Button } from "src/@/components/ui/button";
export default function Component() {
  const [isUploading, setIsUploading] = useState(false);
  const [isLinkGenerated, setIsLinkGenerated] = useState(false);
  const [className, setClassName] = useState("My Class");
  const [classCode, setClassCode] = useState("");
  const [filesArray, setFilesArray] = useState([]);

  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFilesArray(droppedFiles);
  };

  const navigate = useNavigate();
  const navigateFn = () => {
    navigate("/deploy-student-code");
  };
  const getApiCode = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/generate-class-code`,
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );
      if (response.data.status) {
        setClassCode(response.data.data.class_code);
        localStorage.setItem("class_code", response.data.data.class_code);
      }
    } catch (error) {
      console.log("error------->", error.message);
    }
  };
  useEffect(() => {
    getApiCode();
  }, []);

  const handleUpload = async () => {
    setIsLinkGenerated(false);
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("class_code", classCode);
      formData.append("class_name", className);
      formData.append("moduleType", "deployToStudents");

      filesArray.forEach((file, index) => {
        formData.append(`material_files`, file);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/deploy-to-student`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: localStorage.getItem("token"),
          },
        }
      );
      setIsUploading(false);
      // navigateFn();
      if (response.data.status) {
        localStorage.setItem("class_link", response.data.class_link);
        setIsLinkGenerated(true);
      }
    } catch (error) {
      // console.log("err", err);
      if (
        error.response.data.message ==
        "You have reached the limit of 7 uses for this module"
      ) {
        openModal();
      } else {
        toast.error(error.response.data.message);
      }
      setIsUploading(false);
    }
  };

  const features = [
    {
      icon: <FileText className="w-6 h-6" />,
      title: "AI-Generated Notes",
      description: "Comprehensive notes created from your uploaded materials",
    },
    {
      icon: <Book className="w-6 h-6" />,
      title: "Smart Summaries",
      description: "Concise summaries of key concepts and topics",
    },
    {
      icon: <CreditCard className="w-6 h-6" />,
      title: "Interactive Flashcards",
      description: "Engaging flashcards for effective revision",
    },
    {
      icon: <MessageSquare className="w-6 h-6" />,
      title: "Custom Chatbot",
      description: "AI-powered assistant to answer student queries",
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Progress Tracking",
      description: "Monitor student engagement and performance",
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Collaborative Learning",
      description: "Foster peer-to-peer learning and discussions",
    },
  ];

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const filesArray = Array.from(files);
    setFilesArray(filesArray);
  };
  const handleDelete = (itemToDelete) => {
    setFilesArray(filesArray.filter((item) => item !== itemToDelete));
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-600 to-blue-800 text-white">
      <ToastContainer position="top-center" />
      <div className="bg-blue-800">
        <header className="container mx-auto py-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <AfterLoginLogo />
            <input
              type="text"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              className="bg-transparent border-b border-white text-xl font-bold focus:outline-none focus:border-blue-300"
            />
          </div>
          <div className="text-lg font-semibold">
            Class Code:{" "}
            <span className="bg-blue-700 px-2 py-1 rounded">{classCode}</span>
          </div>
        </header>
      </div>

      <main className="container mx-auto px-4 py-8">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white text-gray-800 rounded-xl shadow-xl overflow-hidden max-w-4xl mx-auto"
        >
          <div className="p-8">
            <h2 className="text-3xl font-bold text-center text-blue-600 mb-4">
              Deploy to Students
            </h2>
            <p className="text-gray-600 mb-8 text-center text-sm">
              Upload materials or import from learning platforms. Provide
              AI-enhanced resources to your students.
            </p>
            <div className="space-y-6">
              <div
                className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 rounded-md ${
                  isDragging
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-300 border-dashed"
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                    >
                      <span>Upload files</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        onChange={handleFileUpload}
                        className="sr-only"
                        multiple
                        accept="application/pdf"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500">
                    PDFs, PPTs, DOCs up to 50MB
                  </p>
                </div>
              </div>
              <div>
                <div className="text-gray-500 text-xl font-semibold mb-2">
                  Uploaded Files List-
                </div>
                {filesArray.length ? (
                  filesArray.map((items, index) => {
                    function shortenFileName(fileName) {
                      const maxLengthStart = 25;
                      const maxLengthEnd = 10;

                      if (fileName.length <= maxLengthStart + maxLengthEnd) {
                        return fileName;
                      }

                      // Create the shortened version of the file name
                      const shortenedName =
                        fileName.substring(0, maxLengthStart) + // Start part
                        "..." + // Hidden middle part
                        fileName.substring(fileName.length - maxLengthEnd); // End part

                      return shortenedName;
                    }
                    const shortenedName = shortenFileName(items.name);
                    return (
                      <div
                        className="flex items-center justify-between border border-gray-300 pl-2.5 my-2 w-1/2 rounded-md"
                        key={index}
                      >
                        {shortenedName}
                        <Button
                          variant="ghost"
                          size="icon"
                          className="ml-2"
                          onClick={() => handleDelete(items)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center justify-between border border-gray-300 p-2.5 my-2 w-1/2 rounded-md">
                    No Files Selected
                  </div>
                )}
              </div>
              <div className="flex justify-center space-x-4">
                <button className="flex items-center px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-800 transition-colors duration-200">
                  {/* <Google className="w-5 h-5 mr-2" /> */}
                  <img src={google} className="w-5 h-5 mr-2" />
                  Import from Google Classroom
                </button>
                <button className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors duration-200">
                  <Box className="w-5 h-5 mr-2" />
                  Import from Canvas
                </button>
              </div>
              {!isUploading ? (
                <button
                  disabled={!filesArray.length}
                  onClick={handleUpload}
                  // disabled={isUploading}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                    !filesArray.length
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed "
                      : "bg-blue-600 text-white hover:bg-blue-700"
                  } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200`}
                >
                  Upload
                </button>
              ) : (
                <button
                  // disabled={isUploading}
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${"bg-blue-400"} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200`}
                >
                  Uploading...
                </button>
              )}
              {isLinkGenerated && (
                <div className="mt-4">
                  <button
                    onClick={navigateFn}
                    className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
                  >
                    <LinkIcon className="mr-2 h-5 w-5" />
                    Generate Student Link
                  </button>
                </div>
              )}
            </div>

            {isLinkGenerated && (
              <div className="mt-8">
                <h3 className="text-xl font-bold text-center text-blue-600 mb-4">
                  Student Resources
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {features.map((feature, index) => (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-blue-50 p-4 rounded-lg flex flex-col items-center text-center transition-all duration-300 hover:bg-blue-100"
                    >
                      <div className="bg-blue-500 p-2 rounded-full mb-2">
                        {feature.icon}
                      </div>
                      <h4 className="text-lg font-semibold mb-1">
                        {feature.title}
                      </h4>
                      <p className="text-sm text-gray-600">
                        {feature.description}
                      </p>
                    </motion.div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </main>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-lg bg-white rounded-lg p-6">
          {/* Modal Header */}
          <DialogHeader>
            <DialogTitle className="text-xl text-center font-semibold">
              You have reached the limit of 7 uses for this module
            </DialogTitle>
          </DialogHeader>

          {/* Modal Body */}
          <DialogDescription className="mb-4">
            {/* <p className="text-center text-lg ">Upgrade to our premium plan.</p> */}
            <div className="flex justify-center mt-3">
              <button
                onClick={() => {
                  setIsOpen(false);
                }}
                className="flex items-center outline-none px-4 py-2 mr-5 bg-white text-xl text-blue-600 rounded-md border border-blue-600 cursor-pointer hover:bg-blue-50 transition-colors duration-200 "
              >
                <Clock className="w-5 h-5 mr-2" /> Not Now!
              </button>
              <Link
                to="/payment"
                className="flex items-center bg-blue-600 hover:bg-blue-800 text-white text-xl px-4 py-2 rounded-lg   "
              >
                <CreditCard className="w-5 h-5 mr-2" /> Upgrade Now!
              </Link>
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>

      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link
              href="/privacy"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </footer>

      {/* <footer className="bg-gray-800 text-white py-8 mt-12">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <p>© {new Date().getFullYear()} ProfPilot. All rights reserved.</p>
          <div className="space-x-4">
            <Link
              href="/privacy"
              className="hover:text-gray-300 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms"
              className="hover:text-gray-300 transition-colors duration-200"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </footer> */}
    </div>
  );
}
