import React, { useState } from "react";
import { motion } from "framer-motion";
import { CheckCircle } from "lucide-react";
import { Link } from "react-router-dom";
import AfterLoginLogo from "./DashLogo";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../@/components//ui/dialog";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeContainer from "./StripeContainer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PymentComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  // const [status, setStatus] = useState("");
  const success = (message) => {
    setIsOpen(false);
    toast.success(message);
    setTimeout(() => {
      window.location.href = "/";
    }, 500);
  };
  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);
  return (
    <div className="min-h-screen flex flex-col">
      <ToastContainer position="top-center" />
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
          {/* <Link
            to="/dashboard"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Dashboard
          </Link> */}
          <div className="flex items-center">
            <AfterLoginLogo />
          </div>
        </div>
      </header>
      <section id="pricing" className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12 text-black">
            Choose Your ProfPilot Journey
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0 * 0.1 }}
              className="bg-blue-700 p-8 rounded-xl shadow-xl flex flex-col justify-between transform hover:scale-105 duration-200"
            >
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">
                  ProfPilot Starter
                </h3>
                <p className="text-4xl font-bold mb-1 text-blue-200">Free</p>

                <ul className="mb-8">
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Up to 7 generations per month</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Access to basic AI tools</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Community support</span>
                  </li>
                </ul>
              </div>
              <Link
                to="/"
                className="block w-full text-center bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition-colors transform hover:scale-105 duration-200"
              >
                Get Started
              </Link>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 * 0.1 }}
              className="bg-blue-700 p-8 rounded-xl shadow-xl flex flex-col justify-between transform hover:scale-105 duration-200"
            >
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">
                  ProfPilot Pro
                </h3>
                <p className="text-4xl font-bold mb-1 text-blue-200">$79.99</p>

                <p className="text-sm text-blue-300 mb-6">/year</p>

                <ul className="mb-8">
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Unlimited AI generations</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Priority processing</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Advanced analytics</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Email support</span>
                  </li>
                </ul>
              </div>

              <button
                onClick={openModal}
                className="block w-full text-center bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition-colors transform hover:scale-105 duration-200"
              >
                Get subscription
              </button>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 2 * 0.1 }}
              className="bg-blue-700 p-8 rounded-xl shadow-xl flex flex-col justify-between transform hover:scale-105 duration-200"
            >
              <div>
                <h3 className="text-2xl font-semibold mb-4 text-white">
                  ProfPilot Enterprise
                </h3>
                <p className="text-4xl font-bold mb-1 text-blue-200">Custom</p>

                <ul className="mb-8">
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Custom AI model training</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Custom AI model training</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>API access</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>Dedicated account manager</span>
                  </li>
                  <li className="flex items-center mb-2 text-blue-100">
                    <CheckCircle className="w-5 h-5 mr-2 text-blue-200" />
                    <span>24/7 premium support</span>
                  </li>
                </ul>
              </div>
              <a
                href="https://calendly.com/shubhan-cr0l/30min"
                target="black"
                className="block w-full text-center bg-white text-blue-600 px-6 py-3 rounded-full font-semibold hover:bg-blue-100 transition-colors transform hover:scale-105 duration-200"
              >
                Contact Sales
              </a>
            </motion.div>
          </div>
        </div>
      </section>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-md bg-white rounded-lg p-6">
          {/* Modal Header */}
          {/* <DialogHeader>
            <DialogTitle className="text-xl font-semibold"></DialogTitle>
          </DialogHeader> */}

          {/* Modal Body */}
          <DialogDescription className="mb-4">
            <StripeContainer success={success} />
          </DialogDescription>
        </DialogContent>
      </Dialog>
      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link
              href="/privacy"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PymentComponent;
