import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Check, Copy, Share2 } from "lucide-react";
// import img from 'next/img'
import { Link } from "react-router-dom";
import AfterLoginLogo from "./AfterLoginLogo";

export default function Component() {
  const [className, setClassName] = useState("My Class");
  const [classCode, setClassCode] = useState(
    localStorage.getItem("class_code")
  );

  const [studentLink, setStudentLink] = useState("");
  useEffect(() => {
    const url = `${process.env.REACT_APP_WEB_URL}/${classCode}`;
    setStudentLink(url);
  }, []);
  const [isCopied, setIsCopied] = useState(false);

  // const studentLink = localStorage.getItem("class_code");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(studentLink).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-blue-600 to-blue-800 text-white">
      <div className="bg-blue-800">
        <header className="container mx-auto py-4 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <AfterLoginLogo />
            <input
              type="text"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              className="bg-transparent border-b border-white text-xl font-bold focus:outline-none focus:border-blue-300"
            />
          </div>
          <div className="text-lg font-semibold">
            Class Code:{" "}
            <span className="bg-blue-700 px-2 py-1 rounded">{classCode}</span>
          </div>
        </header>
      </div>
      <main className="w-full container mx-auto px-4 py-8">
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="bg-white text-gray-800 rounded-xl shadow-xl overflow-hidden max-w-4xl mx-auto"
        >
          <div className="p-8">
            <h2 className="text-3xl font-bold text-center text-blue-600 mb-4">
              Deploy to Students
            </h2>
            <div className="flex justify-center mb-8">
              <div className="bg-green-100 text-green-800 rounded-full p-4">
                <Check className="w-16 h-16" />
              </div>
            </div>
            <p className="text-gray-600 mb-8 text-center text-xl">
              Success! Your materials have been deployed.
            </p>
            <div className="bg-gray-100 p-6 rounded-lg mb-8">
              <p className="text-gray-700 mb-2 font-semibold">
                Share this link with your students:
              </p>
              <div className="flex items-center justify-between bg-white border border-gray-300 rounded-md p-2">
                <span className="text-blue-600 font-medium">{studentLink}</span>
                <div className="flex space-x-2">
                  <button
                    onClick={copyToClipboard}
                    className="p-2 bg-blue-100 text-blue-600 rounded-md hover:bg-blue-200 transition-colors duration-200"
                    title="Copy to clipboard"
                  >
                    {isCopied ? (
                      <Check className="w-5 h-5" />
                    ) : (
                      <Copy className="w-5 h-5" />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      /* Implement share functionality */
                    }}
                    className="p-2 bg-blue-100 text-blue-600 rounded-md hover:bg-blue-200 transition-colors duration-200"
                    title="Share link"
                  >
                    <Share2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
            <div className="space-y-4">
              <h3 className="text-xl font-semibold text-center">Next Steps</h3>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>
                  Share the link with your students via email or your preferred
                  communication channel.
                </li>
                <li>
                  Students can access the AI-generated resources by visiting the
                  link and logging in.
                </li>
                <li>
                  Monitor student progress and engagement through your ProfPilot
                  dashboard.
                </li>
              </ul>
            </div>
            <div className="mt-8 flex justify-center">
              <Link
                to="/dashboard"
                className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200"
              >
                Return to Dashboard
              </Link>
            </div>
          </div>
        </motion.div>
      </main>

      <footer className="bg-blue-800 text-white py-8 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row justify-between items-center">
          <p className="mb-4 md:mb-0">
            © {new Date().getFullYear()} ProfPilot. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link
              href="/privacy"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms"
              className="hover:text-blue-200 transition-colors duration-200"
            >
              Terms of Service
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}
